module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-gba/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.gba-group.de","siteId":"7c4ccc7d-7019-40eb-b536-98e0ad1af0dd","branch":"master","algoliaSearchApiKey":"e1eb4969201ad5370ef02aa5ea4d6619","algoliaApplicationId":"9NKPYEEKB4","algoliaQueries":[{"query":"{\n  pages: allStoryblokEntry \n  (filter: {\n    full_slug: {\n       regex: \"/^(?!.*playground\\/).*/\"\n       \n   }\n }\n )\n {\n    edges {\n      node {\n        objectID: id\n        name\n        slug\n        full_slug\n        lang\n        content\n        published_at\n        internal {\n          contentDigest\n          type\n          owner\n        }\n      }\n    }\n  }\n\n  }","indexName":"GBA-Group","settings":{"attributesToSnippet":["name:20","content:50","description:50"]}}],"context":"production","algoliaChunkSize":10000},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
