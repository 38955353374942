import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import jwt from "jwt-decode"

const fetch = require("node-fetch")

const defaultState = {
  isLoggedIn: false,
  checkTokenExpiration: true,
  setUser: () => {},
  getUser: () => {},
  handleLogin: () => {},
  requestPasswordRecovery: () => {},
  handleConfirmation: () => {},
  handlePasswordReset: () => {},
  logout: () => {},
}

const ShopContext = React.createContext(defaultState)

const ShopProvider = ({ children }) => {
  const setUser = (user) =>
    (window.localStorage.GbaShopUser = JSON.stringify(user))
  const getUser = () =>
    window.localStorage.GbaShopUser
      ? JSON.parse(window.localStorage.GbaShopUser)
      : {}

  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const checkTokenExpiration = (accessToken) => {
    const decodedToken = jwt(accessToken)
    const expiry = decodedToken.exp
    const currentTime = Math.floor(Date.now() / 1000)
    return expiry < currentTime
  }

  const handleLogin = async (email, password, callback) => {
    let data = {
      email: email.toLowerCase(),
      password: password,
    }

    let options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    }
    let fetchData = await fetch(
      "https://a61bp2wspe.execute-api.eu-central-1.amazonaws.com/dev/user/login",
      options
    )
      .then((res) => res.json())
      .then((json) => {
        let userJson = JSON.parse(json.body)
        return userJson
      })
      .catch((error) => {
        console.error(error)
        return error.message
      })

    if (fetchData?.message === "Success") {
      setIsLoggedIn(true)
      setUser(fetchData)
    } else {
      callback(false, fetchData.message)
    }
  }

  const handleConfirmation = async (newPassword, oldPassword, callback) => {
    let userToken = getUser()

    let data = {
      email: userToken.email.toLowerCase(),
      previousPassword: oldPassword,
      proposedPassword: newPassword,
    }

    let options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    }

    let fetchData = await fetch(
      "https://a61bp2wspe.execute-api.eu-central-1.amazonaws.com/dev/user/updatePassword",
      options
    )
      .then((res) => res.json())
      .then((json) => {
        let userJson = json.body ? JSON.parse(json.body) : json
        return userJson
      })
      .catch((error) => {
        console.error(error)
        return error
      })

    if (fetchData?.message === "Success") {
      setUser(fetchData)
      callback(true, "Password Updated")
      setIsLoggedIn(true)
    } else {
      callback(
        false,
        fetchData.errorMessage
          ? fetchData.errorMessage
          : "error password did not updated."
      )
    }
  }

  const requestPasswordRecovery = async (email, callback) => {
    let data = {
      username: email.toLowerCase(),
    }

    let options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    }

    let fetchData = await fetch(
      "https://a61bp2wspe.execute-api.eu-central-1.amazonaws.com/dev/user/forgotPassword",
      options
    )
      .then((res) => res.json())
      .then((json) => {
        let userJson = JSON.parse(json.body)
        return userJson
      })
      .catch((error) => {
        console.error(error)
      })

    if (fetchData?.message === "Success") {
      callback(true, "Verification Code Send")
    } else {
      callback(false, fetchData.message)
    }
  }
  const handlePasswordReset = async (code, email, password, callback) => {
    let data = {
      verificationCode: code,
      username: email.toLowerCase(),
      newPassword: password,
    }

    let options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    }

    let fetchData = await fetch(
      "https://a61bp2wspe.execute-api.eu-central-1.amazonaws.com/dev/user/resetPassword",
      options
    )
      .then((res) => res.json())
      .then((json) => {
        let userJson = JSON.parse(json.body)

        return userJson
      })
      .catch((error) => {
        console.error(error)
      })
    console.log("fetchData", fetchData)
    if (fetchData?.message === "Password reset successfull.") {
      callback(true, "Password reset successfull.")
    } else {
      callback(false, fetchData.message)
    }
  }

  const logout = () => {
    setIsLoggedIn(false)
    setUser({})
  }

  useEffect(() => {
    const user = getUser()

    setIsLoggedIn(!!user.email && !user.ChallengeName)
  }, [isLoggedIn])

  useEffect(() => {
    const user = getUser()
    if (user.ChallengeName === "NEW_PASSWORD_REQUIRED") {
      navigate("/gba-shop/confirmation/")
    }
  }, [getUser()])

  useEffect(() => {
    let accessToken
    const user = getUser()
    if (user) {
      accessToken = user?.response?.AuthenticationResult?.AccessToken
      if (accessToken && checkTokenExpiration(accessToken)) {
        logout()
      }
    }
  }, [getUser(), isLoggedIn])

  return (
    <ShopContext.Provider
      value={{
        isLoggedIn,
        setUser,
        getUser,
        handleLogin,
        handleConfirmation,
        requestPasswordRecovery,
        handlePasswordReset,
        logout,
        checkTokenExpiration,
      }}
    >
      {children}
    </ShopContext.Provider>
  )
}

ShopProvider.propTypes = {
  children: PropTypes.node,
}

export default ShopContext

export { ShopProvider }
