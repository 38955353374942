import React, { useState } from "react"
import PropTypes from "prop-types"

const defaultState = {
  customer: {},
  sec: "",
  version: null,
  loadCustomer: () => {},
}

const apiEndPoint =
  "https://xmlzh5adlrrd7ck2mx6vhwnuxe0laroe.lambda-url.eu-central-1.on.aws/"

const BUWaterContext = React.createContext(defaultState)

const BUWaterProvider = ({ children }) => {
  const [customer, setCustomer] = useState({})
  const [sec, setSec] = useState("")
  const [version, setVersion] = useState(null)

  const loadCustomer = (id, sec, version) => {
    const url = version
      ? `${apiEndPoint}?id=${id}&sec=${sec}&v=${version}`
      : `${apiEndPoint}?id=${id}&sec=${sec}`

    setSec(sec)

    if (version) {
      setVersion(version)
    }

    fetch(url)
      .then((response) => response.json())
      .then((customer) => {
        setCustomer(customer)
      })
  }

  return (
    <BUWaterContext.Provider
      value={{
        customer,
        sec,
        version,
        loadCustomer,
      }}
    >
      {children}
    </BUWaterContext.Provider>
  )
}

BUWaterProvider.propTypes = {
  children: PropTypes.node,
}

export default BUWaterContext

export { BUWaterProvider }
