import React, { useState } from "react"
import PropTypes from "prop-types"

const defaultState = {
  searchVisible: false,
  toggleSearchVisibility: () => {},
}

const ThemeContext = React.createContext(defaultState)

const ThemeProvider = ({ children }) => {
  const [searchVisible, setSearchVisible] = useState(false)
  const toggleSearchVisibility = (visible) => setSearchVisible(visible)

  return (
    <ThemeContext.Provider
      value={{
        searchVisible,
        toggleSearchVisibility,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
}

export default ThemeContext

export { ThemeProvider }
