import React, { useEffect, useState } from "react"

const defaultState = {
  setCaptcha: () => {},
  getCaptcha: false,
}

const CaptchaContext = React.createContext(defaultState)

const CaptchaProvider = ({ children }) => {
  const [captachValue, setCaptchaValue] = useState(false)

  const setCaptcha = (value) => {
    setCaptchaValue(value)
  }

  const getCaptcha = () => {
    return captachValue
  }

  return (
    <CaptchaContext.Provider
      value={{
        getCaptcha,
        setCaptcha,
      }}
    >
      {children}
    </CaptchaContext.Provider>
  )
}
export default CaptchaContext
export { CaptchaProvider }
