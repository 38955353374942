import React from "react"

import { ThemeProvider } from "./src/context/theme-context"
import { ShopProvider } from "./src/context/shop-context"
import { BUWaterProvider } from "./src/context/bu-water-context"
import { CaptchaProvider } from "./src/context/captchaContext"

import "fontsource-open-sans"
import "fontsource-open-sans/700.css"

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>
    <BUWaterProvider>
      <ShopProvider>
        <CaptchaProvider>{element}</CaptchaProvider>
      </ShopProvider>
    </BUWaterProvider>
  </ThemeProvider>
)
